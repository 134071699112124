import './FooterPanel.scss';
import {Anchor, Col, Container, Row} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { trackEvent, AMPLITUDE_EVENTS } from '../../util/Amplitude';

export default function FooterPanel() {
    const { t, i18n } = useTranslation();

    const fordFooterLogoClicked = () => {
        const eventProps = { fordButtonLocation: 'bottom' };
        trackEvent(AMPLITUDE_EVENTS.FORD_LOGO_CLICKED, eventProps);
    }

    return (
        <Container fluid={true} className="footer-panel pt-5 pb-5">
            <Container fluid="xxl">
                <Row sm="12" md="auto"
                     className="text-start text-md-center justify-content-md-center align-items-center">
                    <Col xs={{span: 12, order: "last"}} md={{span: "auto", order: "first"}} className="pt-4 p-md-0">
                        <Anchor href="https://www.ford.com">
                            <img
                                src="./Ford.png"
                                onClick={fordFooterLogoClicked}
                                alt={t('landing_page.footer.alt_ford_logo')}/>
                        </Anchor>
                    </Col>
                    <Col xs="12" md="auto" className="ps-md-5 ps-lg-5">{t('landing_page.footer.copyright_notice')}</Col>
                    <Col xs="12" md="auto">
                        <Anchor>{t('landing_page.footer.privacy')}</Anchor>
                    </Col>
                    <Col xs="12" md="auto">
                        <Anchor>{t('landing_page.footer.cookies')}</Anchor>
                    </Col>
                    <Col xs="12" md="auto">
                        <Anchor>{t('landing_page.footer.privacy_rights')}</Anchor>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}
