function getFaq1Body() {
    return (
        <div className={"text-start"}>
            <p>
                <span className="fw-bold">What vehicles can participate in DTE Smart Charge?</span>
                <br/>
                Ford plug-in vehicles model year 2021 or later with an authorized modem in FordPass™ are eligible to participate.
                <br/><br/>
                <span className="fw-bold">What participation incentive will I receive?</span>
                <br/>
                DTE is providing a $50 incentive for enrolling in the program, and an additional $50 for those who stay enrolled through December 2024.  If you have further questions about the incentive, please refer to <a href="https://www.dteenergy.com/content/dam/dteenergy/deg/website/residential/Service-Request/pev/plug-in-electric-vehicles-pev/SmartChargeBrochure.pdf" id="elLink1" rel="noopener noreferrer" target="_blank" className="panel1Links">                    <span className="entity-download-link">the DTE website</span></a>.
                <br/><br/>
                <span className="fw-bold">What is required to participate?</span>
                <br/>
                You need to be a residential electricity customer of DTE. You will need to have an active <a id="elLink2" href="https://www.fordpass.com" rel="noopener noreferrer" target="_blank" className="entity-download-link panel1Links">FordPass™</a> account and your vehicle’s modem authorized. You will need a mobile phone capable of receiving text messages to receive notifications during the program. During enrollment, you will need to identify your home address from a list of recent charge locations. You will also need to set a departure time in the FordPass™ App so Ford SmartGrid Rewards can set your vehicle to complete charging by your preferred time.
                <br/>
            </p>
        </div>
    );
}

function getFaq2Body() {
    return (
        <div className={"text-start"}>
            <p>
                <span className="fw-bold">When might grid-based “pause charge” events occur?</span>
                <br/>
                DTE Smart Charge is scheduled to run from August 2023 through December 2024. The program will only modify your vehicle’s charge settings within that time period. Pause charge events can occur Monday through Friday and will typically last two hours.
                <br/><br/>
                <span className="fw-bold">Where can I charge my vehicle? Where does DTE Smart Charge work?</span>
                <br/>
                You do not need to change your charging habits and can charge your vehicle anywhere.  Ford will update your vehicle's preferred charging times to align with off-peak windows for your specific Time-of-Use rate plan when you charge at your home location. If you live in an apartment complex with multiple charging stations or have multiple residences within DTE’s service territory, you can select multiple locations as your “home” charge location. Pause charge events will only affect charging at the locations you identify during the enrollment process.
                <br/><br/>
                <span className="fw-bold">Why do I need to save a charge location?</span>
                <br/>
                Ford uses Saved Charge Locations in FordPass™ to identify where your vehicle’s charging is subject to adjustment by Ford SmartGrid Rewards.
                <br/><br/>
                <span className="fw-bold">How do I override a grid event if I need to charge my vehicle?</span>
                <br/>
                If you need to charge now in order to leave sooner than expected during a pause charge event, you can reply to the upcoming event text with keyword IGNORE. Alternately, you can override in the FordPass™ App.
                <br/><br/>
                <span className="fds-weight--medium">For Mustang Mach-E and F-150 Lightning:</span>
                <br/>
                <ol className="fds-m--b-1 fds-m--l-3">
                    <li>Click on "Charge Session" from the home screen</li>
                    <li>Click "Start Charging"</li>
                </ol>
                <span className="fds-weight--medium">For Escape Plug-In Hybrid:</span>
                <ol className="fds-m--b-1 fds-m--l-3">
                    <li>Select your vehicle</li>
                    <li>Select "Manage EV"</li>
                    <li>Select "Charge Times"</li>
                    <li>Select "Charge When Plugged In"</li>
                </ol>
                <span className="fw-bold">Why does my vehicle occasionally stop charging?</span>
                <br/>
                DTE will notify Ford when the electric grid is strained needs additional energy savings. This is typically a request to pause charge for a couple of hours, which helps sustain the reliability of the electric grid.
                <br/><br/>
                <span className="fw-bold">What if I unexpectedly need to use my vehicle when a grid event is in effect?</span>
                <br/>
                If you need to use your vehicle, you can always unplug it and drive without having an impact on your participation in the program.                  If you need to charge during a pause charge event to leave sooner than expected, you can reply to the upcoming event text with keyword IGNORE. Alternately, you can override in the FordPass™ App.
                <br/><br/>
                <span className="fds-weight--medium">For Mustang Mach-E and F-150 Lightning:</span>
                <br/>
                <ol className="fds-m--b-1 fds-m--l-3">
                    <li>Click on "Charge Session" from the home screen</li>
                    <li>Click "Start Charging"</li>
                </ol>
                <span className="fds-weight--medium">For Escape Plug-In Hybrid:</span>
                <br/>
                <ol className="fds-m--b-1 fds-m--l-3">
                    <li>Select your vehicle</li>
                    <li>Select "Manage EV"</li>
                    <li>Select "Charge Times"</li>
                    <li>Select "Charge When Plugged In"</li>
                </ol>
                <span className="fw-bold">Will this impact the life of my vehicle’s battery?</span>
                <br/>
                Your Ford electric vehicle can be charged as often as needed with either a Level 1 (120V AC or standard household outlet) or Level 2 charger (240V AC dryer type outlet or hardwired charger). Starting or pausing charging will not have an adverse impact on high voltage battery life. Always consult your Owner’s Manual for full information about charging and your vehicle’s battery.
                <br/><br/>
                <span className="fw-bold">Why am I getting texts that my charging preferences could not be updated for an event or could not be restored to pre-event status?</span>
                <br/>
                If you receive a text message that we are unable to update your vehicle for the pause charging event, this means we were unable to connect to your vehicle’s modem to update the vehicle charge settings during the event. This could be due to variety of factors, such as your vehicle's location blocking connectivity to receive the update for that particular event(e.g., if you are parked underground, etc). If you receive this text message frequently, it may be due to another issue. Please contact your dealer to help re-establish connectivity.
                <br/>
            </p>
        </div>
    );
}

function getFaq3Body() {
    return (
        <div className={"text-start"}>
            <p>
                <span className="fw-bold">How do I unenroll from the DTE Smart Charge program?</span>
                <br/>
                If you wish to unenroll in the program, you can do so in your SmartGrid Rewards <a id="dashboardLink" href="dte/dashboard" rel="noopener noreferrer" target="_blank" className="entity-download-link panel3Links dashboardLinks" tabIndex="-1"> Account Dashboard</a>
                <br/>
            </p>
        </div>
    );
}

function getFaq4Body() {
    return (
        <div className={"text-start"}>
            <p className="fmc-type--body1 inner-accordion-text fds-color__text--gray3">
                <span className="fw-bold">What if I need help or have questions during the program?</span>
                <br/>
                If you have questions related to charging your vehicle or paused charging, please visit your <a id="dashboardLink" href="dte/dashboard" rel="noopener noreferrer" target="_blank" className="entity-download-link panel3Links dashboardLinks" tabIndex="-1"> Account Dashboard</a> to submit a Technical Support request, or contact Ford at (800) 392-3673.
                If you have questions specific to the DTE Smart Charge program or your incentive, please contact DTE at <a id="helpLink1" href="mailto:SmartCharge@dteenergy.com" target="_blank" className="panel4Links" tabIndex="-1">
                <span className="entity-download-link">SmartCharge@dteenergy.com</span> </a>
            </p>
        </div>
    );
}

export const faqData = [
    {
        title: "Eligibility and Incentives",
        content: getFaq1Body()
    },
    {
        title: "Charging, Scheduling and Opting Out",
        content: getFaq2Body()
    },
    {
        title: "Enrollment/Unenrollment",
        content: getFaq3Body()
    },
    {
        title: "Additional Help",
        content: getFaq4Body()
    }
]