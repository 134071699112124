import env from "../env/environment";

let ebApi = env.enrollmentBrokerApi

export function getUtilityProgramsByPostcode(zipCode) {
    let url = ebApi.BASE_URL + ebApi.SEARCH_PROGRAMS_PATH + encodeURI(zipCode)
    // url = "http://localhost:3000/tests/byPostcode.json?postCode=" + encodeURI(zipCode);
    let requestConfig = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        }
    }
    return fetch(url, requestConfig).then(
        response => {
            if (!response.ok) {
                throw new Error(response.statusText)
            }
            return response.json()
        })
}

export function submitEmailForKMI(emailAddress, zipCode) {
    let url = ebApi.BASE_URL + ebApi.KMI_PATH;
    let requestConfig = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: emailAddress, postalCode: zipCode }),
    }
    return fetch(url, requestConfig)
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response;
        })
}
