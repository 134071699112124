import './HeroPanel.scss';
import {Button, Container} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { trackEvent, AMPLITUDE_EVENTS } from '../../util/Amplitude';

export default function HeroPanel({ toggleDialog }) {
    const { t, i18n } = useTranslation();
    const heroPanelEnrollNowClicked = () => {
        const eventProps = { enrollButtonLocation: 'top' };
        trackEvent(AMPLITUDE_EVENTS.ENROLL_NOW,eventProps);
    }

    const showModal = () => {
        heroPanelEnrollNowClicked();
        toggleDialog();
    }

  return (
      <Container fluid={true} className="pt-5 pb-5 hero-panel" alt={t('landing_page.hero_panel.alt_ford_background')}>
          <Container className="hero-text-block">
              <h1 className="hero-header-text font-colour mb-3">
                  {t('landing_page.hero_panel.hero_banner_part_1')} <br />
                  {t('landing_page.hero_panel.hero_banner_part_2')}
              </h1>
              <h3 className="hero-content-text font-colour mb-3">{t('landing_page.hero_panel.hero_subtitle')}</h3>
              <p>
                  <Button
                      className="rounded-5 button-style button-font"
                      onClick={showModal}
                  >
                      {t('landing_page.hero_panel.enroll_now')}
                  </Button>
              </p>
          </Container>
      </Container>
  );
}
