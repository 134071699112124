import React, {useEffect, useState} from 'react';
import {Button, Col, Modal} from 'react-bootstrap';
import './PopupModal.scss';
import ZipCodeSearch from "../ZipCodeSearch/ZipCodeSearch";
import {useTranslation} from 'react-i18next';
import {AMPLITUDE_EVENTS, trackEvent} from "../../util/Amplitude";
import { useRecoilValue } from 'recoil';
import { utilitySelectedNameState } from '../../recoilState';
import { utilitySelectedProgramState } from '../../recoilState';


export default function PopupModal({displayDialog, closeDialog, dialogTitle}) {
    const {t, i18n} = useTranslation();
    const utilitySelectedName = useRecoilValue(utilitySelectedNameState);
    const utilitySelectedProgram = useRecoilValue(utilitySelectedProgramState);
    const [hasSearched, setHasSearched] = useState(false);
    const [searchStatus, setSearchStatus] = useState('beforeSearchingUtility');
    const [kmiStatus, setKmiStatus] = useState('before');
    const [noParticipatingUtilitiesFoundOpened, setNoParticipatingUtilitiesFoundOpened] = useState(false);

    const enrollNow = (event) => {
        event.preventDefault();
        const eventProps = {utilityName: utilitySelectedName, programSelected: utilitySelectedProgram};
        trackEvent(AMPLITUDE_EVENTS.UTILITY_LOOKUP_ENROLL_CLICKED, eventProps);
    }

    const handleClose = () => {

        let utilityLookUpCloseButton;
        if (noParticipatingUtilitiesFoundOpened) {
            utilityLookUpCloseButton = kmiStatus === 'after' ? 'afterKmi' : 'beforeKmi';
        } else {
            utilityLookUpCloseButton = searchStatus;
        }

        const eventProps = {
            utilityLookUpCloseButton: utilityLookUpCloseButton
        };
        trackEvent(AMPLITUDE_EVENTS.UTILITY_CLOSE_BUTTON_CLICK, eventProps);
        closeDialog();

        // Reset states back to false
        setHasSearched(false);
        setKmiStatus('before'); // Reset kmiStatus back to 'before'
        setNoParticipatingUtilitiesFoundOpened(false);

    }

    useEffect(() => {
        setSearchStatus(hasSearched ? 'afterSearchingUtility' : 'beforeSearchingUtility');
    }, [hasSearched]);


    return (

        <Modal show={displayDialog} onHide={closeDialog} animation={true} dialogClassName={'modal-lg'}>
            <Modal.Header closeButton>
                <Modal.Title as="h4">{dialogTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ZipCodeSearch setHasSearched={setHasSearched} setKmiStatus={setKmiStatus} setNoParticipatingUtilitiesFoundOpened={setNoParticipatingUtilitiesFoundOpened} />
            </Modal.Body>
            <Modal.Footer className="border-top-0 pb-4">
                <Col xs={{span: 12, order: "last"}} md={{span: "3", order: "first"}} lg="2" className="d-grid pb-3">
                    <Button
                        className="rounded-5 button-style close-button-font d-block"
                        variant="outline-primary"
                        onClick={handleClose}
                    >
                        {t('landing_page.popup_modal.btn_close')}
                    </Button>
                </Col>
                <Col xs="12" md="3" lg="2" className="d-grid pb-3">
                    <Button
                        className="rounded-5 button-style button-font d-block"
                        onClick={enrollNow}
                    >
                        {t('landing_page.popup_modal.btn_enroll')}
                    </Button>
                </Col>
            </Modal.Footer>
        </Modal>
    );
}
