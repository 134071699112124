import {Col, Container, Navbar, NavLink} from "react-bootstrap";
import './Header.scss'
import { useTranslation } from 'react-i18next';
import { trackEvent, AMPLITUDE_EVENTS } from '../../util/Amplitude';

export default function Header()
{
    const { t, i18n } = useTranslation();
    const homeIconClick = () => {
            trackEvent(AMPLITUDE_EVENTS.HOME_ICON_CLICKED);
    }
    const fordLogoClicked = () => {
            const eventProps = { fordButtonLocation: 'top' };
            trackEvent(AMPLITUDE_EVENTS.FORD_LOGO_CLICKED, eventProps);
    }
    const profileIconClicked = () => {
            trackEvent(AMPLITUDE_EVENTS.ACCOUNTS_ICON_CLICKED);
    }

    return (
        <>
            <Navbar className="app-header">
                <Container>
                    <Col className="text-start" span="4">
                        <Navbar.Brand href="/"
                              onClick={homeIconClick}
                        >{t('landing_page.header.home')}</Navbar.Brand>
                    </Col>
                    <Col aria-colspan="4">
                        <NavLink id="ford-logo-container"
                                 href="https://www.ford.com"
                                 onClick={fordLogoClicked}
                        >
                            <img
                                src="./Ford.png"
                                alt={t('landing_page.header.alt_ford_image')}/>
                        </NavLink>
                    </Col>
                    <Col aria-colspan="4" className="text-end">
                        <NavLink href="#"
                                 onClick={profileIconClicked}
                        >
                            <img src="./Profile.png" alt={t('landing_page.header.alt_profile')}/>
                        </NavLink>
                    </Col>
                </Container>
            </Navbar>
        </>
    )
}