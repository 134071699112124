import './VideoPanel.scss';
import {Col, Container, Row} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import {AMPLITUDE_EVENTS, trackEvent} from "../../util/Amplitude";
import ReactPlayer from "react-player";
import {useState, useEffect} from "react";


export default function VideoPanel() {
    const { t, i18n } = useTranslation();
    const [videoStarted, setVideoStarted] = useState(null);
    const [videoPaused, setVideoPaused] = useState(null);
    const videoTrackMetrics = (event) => {
        if (event === 'play') {
            setVideoStarted(new Date().toISOString());
            setVideoPaused(null);
        } else if (event === 'pause') {
            setVideoPaused(new Date().toISOString());
        }
    }

    useEffect(() => {
        let viewedTime = null;
        if (videoStarted && videoPaused) {
            const start = new Date(videoStarted);
            const pause = new Date(videoPaused);
            viewedTime = (pause.getTime() - start.getTime()) / 1000 + 'seconds';
        }
        const eventProps = {
            videoStarted,
            videoPaused,
            viewedTime,
        };
        trackEvent(AMPLITUDE_EVENTS.VIDEO_PLAYED, eventProps);
    }, [videoStarted, videoPaused]);

    return (
      <Container fluid={true} className='video-panel'>
          <Row className='justify-content-md-center'>
              <Col md='9'>
                  <h2 className='video-header'>{t('landing_page.video_panel.how_it_works')}</h2>
              </Col>
          </Row>
          <Row className='justify-content-md-center h-75'>
              <Col md='9'>
                  <div className='video-wrapper' tabIndex="0">
                      {/*<embed className={'video-content'} src="https://www.youtube.com/embed/uPn-paGOk24"></embed>*/}
                      <ReactPlayer
                          className='video-content'
                          url='https://www.youtube.com/watch?v=uPn-paGOk24'
                          width='100%'
                          height='100%'
                          config={{
                              youtube: {
                                  playerVars: {
                                      controls: 1,
                                  }
                              }
                          }}
                          onPlay={() => videoTrackMetrics('play')}
                          onPause={() => videoTrackMetrics('pause')}

                      />                 {/*used placeholder video for now*/}
                  </div>
              </Col>
          </Row>
      </Container>
  );
}
