import './FAQItem.scss';
import {useState} from "react";
import {Col, Row} from "react-bootstrap";
import {AMPLITUDE_EVENTS, trackEvent} from "../../util/Amplitude";

export default function FAQItem(props) {
  const [isActive, setIsActive] = useState(false);
  const titleColour = isActive ? "text-primary" : "";
  const trackOnViewingFAQItem = (expanded) => {
      if(expanded) {
          const title = props.title.replaceAll(",","");
          const eventProps = {accordionName: title};
          trackEvent(AMPLITUDE_EVENTS.FAQ_EXPANDED, eventProps);
      }
  }

  const accordionState = () => {
      const expanded = !isActive;
      setIsActive(expanded);
      trackOnViewingFAQItem(expanded);
  }

  return (
    <div className='faq-item accordion-item ps-2 pt-4 pb-4 border-1 border-start-0 border-end-0  border-top border-bottom-0 border-secondary rounded-0'>
      <div className='accordion-header h-100 faq-title-1'
           onClick={accordionState}
      >
        <div>
            <Row>
                <Col className={"text-start " + titleColour} xs={6}>
                    <h4 className={"faq-title-1 mb-0 " + titleColour}>{props.title}</h4>
                </Col>
                <Col className={"text-end text-primary pe-4 faq-title-2"} xs={6} tabIndex="0">
                    {isActive ? "-" : "+"}
                </Col>
            </Row>
        </div>
      </div>
      {isActive && <div className={"accordion-body text-start faq-body"}>{props.content}</div>}
    </div>
  );
}
