import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import environment from "./env/environment";

const { version } = require('../package.json');
i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: function (language, namespace) {
                const base = '/locales';
                return `${base}/${language}/${namespace}.json?v=${version}`;
            },
        },
        returnEmptyString: false,
        debug: !environment.production,
        fallbackLng: 'en-US',
    });

export default i18n;