import { atom } from 'recoil';

export const utilitySelectedNameState = atom({
    key: 'utilitySelectedNameState',
    default: '',
    });

export const utilitySelectedProgramState = atom({
    key: 'utilitySelectedProgramState',
    default: '',
});