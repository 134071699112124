import './NoParticipatingUtilitiesFound.scss';
import {Alert, Button, Col, Container, Form, Image, Row} from "react-bootstrap";
import NotificationImage from "../../assets/images/png-images/Notification_icon.png"
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from 'react';
import {AMPLITUDE_EVENTS, trackEvent} from "../../util/Amplitude";
import KmiSuccessMessage from '../KmiSuccessMessage/KmiSuccessMessage';
import {submitEmailForKMI} from '../../clients/EnrollemntBroker';

export default function NoParticipatingUtilitiesFound({zipCode, submitKMIEmail = submitEmailForKMI, setKmiStatus, setNoParticipatingUtilitiesFoundOpened }) {
    const { t, i18n } = useTranslation();
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [isResponseOk, setIsResponseOk] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false)

    useEffect(() => {
        setNoParticipatingUtilitiesFoundOpened(true);
    }, []);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setIsEmailValid(true);
    }

    const handleButtonClick = async (e) => {
        e.preventDefault();
        setKmiStatus('before');
        const emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/;
        if (!emailRegex.test(email)) {
            setIsEmailValid(false);
            return
        }
        trackEvent(AMPLITUDE_EVENTS.NOTIFY_ME_KMI);
        try {
            await submitKMIEmail(email, zipCode);
            setIsResponseOk(true);
            setShowSuccessMessage(true);
            setKmiStatus('after');
        } catch (error) {
            setShowErrorMessage(true)
        }
    }

    if (isResponseOk) {
        return <KmiSuccessMessage />;
    } else if(showErrorMessage) {
        return (
            <div className="mt-3">
                <Alert variant="danger" className="text-start d-table w-100">
                    <h2 className="float-start py-2 pe-3">
                        <i className="bi-exclamation-triangle"></i>
                    </h2>
                    <Alert.Heading>{t("landing_page.no_participating_utilities.error.heading")}</Alert.Heading>
                    {t("landing_page.no_participating_utilities.error.description")}
                </Alert>
            </div>
        );
    }

    return (
        <Container fluid={true} className="mt-4 pt-3 pb-3 orange-box" data-testid="no-utilities-found">
            <Row className={"text-start title-position"}>
                <Col className={"ps-3 image-position"} >
                    <Image src={NotificationImage} alt={t('landing_page.no_participating_utilities.alt_Notification_icon')} width={25}/>
                </Col>
                <Col xs={11} className={"text-start no-participating-utilities-found-title title-gap ps-0"}>
                    {t('landing_page.no_participating_utilities.title_no_utility_found')} {zipCode}
                </Col>
            </Row>
            <Row className={"text-start pt-2"}>
                <Col className={"px-0"} >
                </Col>
                <Col xs={11} className={"text-start no-participating-utilities-found-text ps-0"}>
                    {t('landing_page.no_participating_utilities.info_no_utility_found')}
                </Col>
            </Row>
            <Row className={"text-start pt-2"}>
                <Col xs={1} className={"px-0"} >
                </Col>
                <Col xs={6} className={"text-start ps-0"}>
                    <Form className={`text-box-width no-participating-utilities-found-email mb-3 ms-0 ${!isEmailValid ? 'email-error' : ''}`} onSubmit={handleButtonClick}>                        <Form.Group>
                            <div className={"pt-2"}>
                                <Form.Text className={"no-participating-utilities-found-email pb-5"}>{t('landing_page.no_participating_utilities.email_address')}</Form.Text>
                            </div>
                            <div className={"pt-2"}>
                                <Form.Control size={"lg"} type="email" onChange={handleEmailChange} isInvalid={!isEmailValid} value={email} />
                                <Form.Control.Feedback type="invalid">
                                    {t('landing_page.no_participating_utilities.invalid_email_address')}
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>
                        <div className={"kmi-button-style centre-notify-button"}>
                            <Button className="rounded-5 button-font float-start button-style" type="submit">
                                {t('landing_page.no_participating_utilities.btn_notify_me')}
                            </Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}