import './InfoGraphic.scss';
import {Col, Image, Row} from "react-bootstrap";

export default function InfoGraphic(
    {
        imageUrl,
        imageAlt,
        title = "",
        infoText,
        layoutMode = 'responsive',
        textAlign = "center",
        textColour = "",
        incomingClass = "",
        imageClassName=""

    }
) {

    let layouts = ['responsive', 'horizontal', 'vertical'];
    layoutMode = layoutMode.toLowerCase()
    layoutMode = layouts.includes(layoutMode) ? layoutMode : 'responsive';

    let textAlignmentClasses = "p-2 text-start text-md-" + textAlign.replace(" ", "")
    let colSize = {
        image: {
            small: 3,
            other: 12
        },
        text: {
            small: 9,
            other: 12
        }
    }

    switch (layoutMode) {
        case 'horizontal':
            colSize.image.other = 3
            colSize.text.other = 9
            break;
        case 'vertical':
            colSize.image.small = 12
            colSize.text.small = 12
            break;
    }
    if (incomingClass === 'WhatYouNeedPanel') {
        colSize.text.other = 6
        colSize.image.other = 6
        colSize.image.small = 2
        colSize.text.small = 8
    }

    return (
        <>
            <Row className={"justify-content-md-center align-items-center p-1"}>
                <Col xs={colSize.image.small} sm={colSize.image.small} md={colSize.image.other}>
                    <Image className={"rounded-3 " + imageClassName} fluid={true} src={imageUrl} alt={imageAlt}/>
                </Col>
                <Col xs={colSize.text.small} sm={colSize.text.small} md={colSize.text.other}
                    className={textAlignmentClasses + " " + textColour}
                >
                    {title ? (<h3 className="info-title mb-3">{title}</h3>) : ''}
                    <span className="info-text">{infoText}</span>
                </Col>
            </Row>
        </>
    );
}
