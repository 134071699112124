import './FAQPanel.scss';
import FAQItem from '../FAQItem/FAQItem';
import {Container} from "react-bootstrap";
import {faqData} from "./FAQData";
import {v4 as uuidv4} from 'uuid';

export default function FAQPanel() {
  return (
    <div className='faq-panel'>
        <Container fluid={true} className="pt-5 pb-5">
            <h2 className="text-start faq-header mb-3">FAQs</h2>
            <div className={"accordion"}>
                {faqData.map(({title, content}) => <FAQItem key={uuidv4()} title={title} content={content} />)}
            </div>
            <p className="text-start faq-note">
                <br/>
            * FordPass App, compatible with select smartphone platforms, is available via a download. Message and data rates may apply.
            </p>
        </Container>
    </div>
  );
}
