import * as Amplitude from '@amplitude/analytics-browser';
import config from '../env/environment.js';

export const AMPLITUDE_EVENTS = {
    ENROLL_NOW: 'enroll button clicked',
    FIND_UTILITY: 'find your utility button clicked',
    UTILITY_CLOSE_BUTTON_CLICK: 'utility lookup close button clicked',
    SEARCH_UTILITIES_BY_ZIPCODE_BUTTON_CLICKED: 'Utility lookup Search button clicked',
    PROGRAM_TAB_CLICKED: 'program tab tapped',
    UTILITY_LOOKUP_ENROLL_CLICKED: 'utility lookup enroll button clicked',
    NOTIFY_ME_KMI: 'utility lookup notify me button clicked',
    FORD_LOGO_CLICKED: 'ford logo icon clicked',
    HOME_ICON_CLICKED: 'home icon clicked',
    ACCOUNTS_ICON_CLICKED: 'accounts icon clicked',
    VIDEO_PLAYED: 'embedded video played',
    FAQ_EXPANDED: 'faq accordion expanded'
};


export const initAmplitude = () => {
    Amplitude.init(config.AMPLITUDE_API_KEY, {
        defaultTracking: true
    });
};

export const identifyUser = (VIN, email, userType) => {
    if (email) Amplitude.setUserId(email);
    else Amplitude.setUserId(VIN);
    Amplitude.setGroup('User Type', userType);
};

export const trackEvent = (eventName, eventProps = { Timestamp: new Date().toISOString() }) => {
    Amplitude.track(eventName, eventProps);
};
