import './ZipCodeSearch.scss';
import {Button, Col, Container, Row, Form, Alert} from "react-bootstrap";
import {useTranslation} from 'react-i18next';
import {useState} from "react";
import {trackEvent, AMPLITUDE_EVENTS} from '../../util/Amplitude';

import UtilityProgramResults from "../UtilityProgramResults/UtilityProgramResults";
import NoParticipatingUtilitiesFound from "../NoParticipatingUtilitiesFound/NoParticipatingUtilitiesFound";
import {getUtilityProgramsByPostcode} from "../../clients/EnrollemntBroker";

export default function ZipCodeSearch({fetchUtilityPrograms = getUtilityProgramsByPostcode, setHasSearched, setKmiStatus, setNoParticipatingUtilitiesFoundOpened}) {
    const {t, i18n} = useTranslation();
    const [state, setState] = useState({
        zipCode: "",
        isValidZipCode: false,
        searchSubmitted: false,
        resultsLoading: false,
        resultsFound: false,
        resultsNotFound: false,
        errorOccurred: false,
        utilityPrograms: new Map()
    });
    const [zipCodeValid, setZipCodeValid] = useState(false);
    const [searchButtonClicked, setSearchButtonClicked] = useState(false);

    const DPDetails = {
        title: t('landing_page.utility_programs.DP.title'),
        description: t('landing_page.utility_programs.DP.description'),
        enrollment: t('landing_page.utility_programs.DP.enrollment_desc'),
        chargingIncentives: t('landing_page.utility_programs.DP.charging_incentives_desc')
    }
    const DRDetails = {
        title: t('landing_page.utility_programs.DR.title'),
        description: t('landing_page.utility_programs.DR.description'),
        enrollment: t('landing_page.utility_programs.DR.enrollment_desc'),
        chargingIncentives: t('landing_page.utility_programs.DR.charging_incentives_desc')
    }
    const TOUDetails = {
        title: t('landing_page.utility_programs.TOU.title'),
        description: t('landing_page.utility_programs.TOU.description'),
        enrollment: t('landing_page.utility_programs.TOU.enrollment_desc'),
        chargingIncentives: t('landing_page.utility_programs.TOU.charging_incentives_desc')
    }
    const DRTOUDetails = {
        title: t('landing_page.utility_programs.DRTOU.title'),
        description: t('landing_page.utility_programs.DRTOU.description'),
        enrollment: t('landing_page.utility_programs.DRTOU.enrollment_desc'),
        chargingIncentives: t('landing_page.utility_programs.DRTOU.charging_incentives_desc')
    }

    const programDescriptions = new Map([
        ["DP", DPDetails],
        ["DR", DRDetails],
        ["TOU", TOUDetails],
        ["DRTOU", DRTOUDetails]
    ])

    async function handleSearchSubmit(e) {
        e.preventDefault();
        setSearchButtonClicked(true);
        trackEvent(AMPLITUDE_EVENTS.SEARCH_UTILITIES_BY_ZIPCODE_BUTTON_CLICKED);
        const isValid = validateZip(state.zipCode);
        let stateChange = resetUtilityResults()
        stateChange.isValidZipCode = isValid
        stateChange.searchSubmitted = true;
        if (isValid) {
            stateChange.resultsLoading = true
            setState(stateChange)
        } else {
            setState(stateChange)
            return;
        }
        try {
            let responseBody = await fetchUtilityPrograms(state.zipCode)
            processSearchResults(responseBody.utilityProgram)
            console.log('Current hasSearched state:', state.hasSearched);
            setHasSearched(true);


        } catch (e) {
            let stateWithError = resetUtilityResults()
            stateWithError.errorOccurred = true;
            setState(stateWithError);
        }
    }

    function handleZipCodeKeyUp(event) {
        event.preventDefault();
        if (["Enter", "NumpadEnter"].includes(event.code)) { // enter key
            handleZipCodeChange(event)
            handleSearchSubmit(event);
        }
    }

    function handleZipCodeChange(event) {
        event.preventDefault();
        let changedState = resetUtilityResults();
        changedState.zipCode = event.target.value;
        setState(changedState);
        setSearchButtonClicked(false);
        if (validateZip(changedState.zipCode)) {
            setZipCodeValid(true);
        }
    }

    function validateZip(zip) {
        const zipRegex = /^\d{5}(-\d{4})?$/;
        return zipRegex.test(zip);
    }

    function resetUtilityResults() {
        let currentState = {...state};
        currentState.isValidZipCode = true;
        currentState.resultsFound = false;
        currentState.resultsLoading = false;
        currentState.resultsNotFound = false;
        currentState.errorOccurred = false;
        currentState.utilityPrograms = new Map();
        return currentState;
    }

    /**
     * @param {List<any>} utilityPrograms
     */
    function processSearchResults(utilityPrograms) {
        let utilityProgramsStructured = groupAndMapUtilityPrograms(utilityPrograms)
        let changedState = resetUtilityResults();
        if (utilityProgramsStructured.size === 0) {
            changedState.resultsNotFound = true;
        } else {
            changedState.utilityPrograms = Array.from(utilityProgramsStructured.values())
            changedState.resultsFound = true;
        }
        setState(changedState);
    }

    function groupAndMapUtilityPrograms(utilityPrograms) {
        const map = new Map();
        for (let index in utilityPrograms) {
            let utilityProgram = utilityPrograms[index];
            let key = utilityProgram.utilityCode;
            if (!map.has(key)) {
                map.set(key, {
                    utilityCode: utilityProgram.utilityCode,
                    utilityName: utilityProgram.utilityName,
                    programs: []
                })
            }
            let item = map.get(key)

            item.programs.push({
                id: index,
                name: utilityProgram.programName,
                details: programDescriptions.get(utilityProgram.programCode)
            })
        }
        return map;
    }

    return (
        <Container fluid={true} className="p-0">
            <Form>
                <Col className={'text-start py-2'} xs={12}>
                    <Form.Label
                        className={'model-label ps-md-3'}
                    >
                        {t('landing_page.zipcode_search.info_text_enter_your_zipcode')}
                    </Form.Label>
                </Col>
                <Col className={'mx-auto'} xs={12} md={6}>
                    <div className={'zipcodeOuter'}>
                        <Form.Control
                            plaintext
                            readOnly
                            className={'form-control-plaintext model-label'}
                            defaultValue={t('landing_page.zipcode_search.your_zipcode')}
                        />
                    </div>
                </Col>
                <Col className={'mx-auto'} xs={12} md={6}>
                    <div className={'zipcodeOuter'}>
                        <Form.Control
                            size="lg"
                            type="text"
                            className={`zipcodeInputBox border-secondary ${!state.isValidZipCode && state.searchSubmitted ? 'invalid-input' : ''}`}
                            isInvalid={!state.isValidZipCode && state.searchSubmitted}
                            isValid={zipCodeValid && searchButtonClicked}
                            onKeyUp={handleZipCodeKeyUp}
                            onChange={handleZipCodeChange}
                        />
                        <Form.Control.Feedback className={'invalid_zipcode'} type="invalid">
                            {t('landing_page.zipcode_search.invalid_zipcode')}
                        </Form.Control.Feedback>
                    </div>
                </Col>
                <Col className={'mx-auto pt-3'} xs={9} md={5} lg={3}>
                    <Button className={'rounded-5 button-style button-font'}
                            onClick={handleSearchSubmit}
                            disabled={state.resultsLoading}
                    >
                        {t('landing_page.zipcode_search.btn_search_utilities')}</Button>
                </Col>
            </Form>
            {state.resultsLoading && (
                <div className="text-center m-2">
                    <div className="spinner-border" role="status" data-testid="utility-programs-search-loading">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )
            }
            {state.resultsFound && <UtilityProgramResults utilityProgramData={state.utilityPrograms}/>}
            {state.resultsNotFound && <NoParticipatingUtilitiesFound zipCode={state.zipCode} setKmiStatus={setKmiStatus} setNoParticipatingUtilitiesFoundOpened={setNoParticipatingUtilitiesFoundOpened}/>}
            {state.errorOccurred && (
                <Alert variant="danger" className="text-start d-table w-100 mt-3">
                    <h2 className="float-start py-2 pe-3">
                        <i className="bi-exclamation-triangle"></i>
                    </h2>
                    <Alert.Heading>{t("landing_page.zipcode_search.error.heading")}</Alert.Heading>
                    {t("landing_page.zipcode_search.error.description")}
                </Alert>
            )}
        </Container>
    );
}
