import './LandingPage.scss';
import Header from "../../components/Header/Header";
import HeroPanel from '../../components/HeroPanel/HeroPanel';
import LearnPanel from '../../components/LearnPanel/LearnPanel';
import VideoPanel from '../../components/VideoPanel/VideoPanel';
import ProcessInfoPanel from '../../components/ProcessInfoPanel/ProcessInfoPanel';
import WhatYouNeedPanel from '../../components/WhatYouNeedPanel/WhatYouNeedPanel';
import FAQPanel from '../../components/FAQPanel/FAQPanel';
import FooterPanel from '../../components/Footer/FooterPanel';
import {Container} from "react-bootstrap";
import PopupModal from "../../components/PopupModal/PopupModal";
import React, {Suspense, useState} from "react";
import { useTranslation } from 'react-i18next';


function LandingPage() {

    const [showUtilitiesDialog, setShowUtilitiesDialog] = useState(false);
    const toggleUtilitiesDialog = () => setShowUtilitiesDialog(!showUtilitiesDialog);
    const { t, i18n } = useTranslation();

    return (
        <>
            <PopupModal
                className=""
                id="edit-dialog"
                displayDialog={showUtilitiesDialog}
                closeDialog={toggleUtilitiesDialog}
                dialogTitle={t('landing_page.popup_modal.lookup_utility')}
            />

            <Container fluid="xxl" className="landing-page">
                <Header/>
                <HeroPanel
                    toggleDialog={toggleUtilitiesDialog}/>
                <LearnPanel
                    toggleDialog={toggleUtilitiesDialog}
                />
                <VideoPanel/>
                <ProcessInfoPanel
                    toggleDialog={toggleUtilitiesDialog}
                />
                <WhatYouNeedPanel/>
                <FAQPanel/>
            </Container>
            <FooterPanel/>
        </>
    );
}

export default function WrappedApp() {
    return (
        <Suspense fallback="...loading">
            <LandingPage />
        </Suspense>
    );
}