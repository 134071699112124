import './LearnPanel.scss';
import {Button, Col, Container, Image, Row} from "react-bootstrap";
import InfoGraphic from "../InfoGraphic/InfoGraphic";
import cashImage from "../../assets/images/png-images/One_time_cash_rewards.png"
import chargerImage from "../../assets/images/png-images/Incentives_home_charger.png"
import discountsImage from "../../assets/images/png-images/Ongoing_discounts.png"
import { useTranslation, Trans } from 'react-i18next';
import { trackEvent, AMPLITUDE_EVENTS } from '../../util/Amplitude';

export default function LearnPanel({ toggleDialog }) {
    const { t, i18n } = useTranslation();

    const findUtilityClicked = () => {
        trackEvent(AMPLITUDE_EVENTS.FIND_UTILITY);
    }

    const learnPanelFindUtilityClicked = () => {
        findUtilityClicked();
        toggleDialog();
    }

    return (
        <>
        <Container fluid={true} className="pt-5 pb-5">
            <h2 className="text-center learn-title">{t('landing_page.learn_panel.learn_title')}</h2>
            <p className="text-center learn-content">
                <Trans
                    i18nKey="landing_page.learn_panel.learn_content"
                    components={{ b: <strong /> }}
                />
            </p>
            <Row  className={"infographics"}>
                <Col sm={12} md={4}>
                    <InfoGraphic
                        imageUrl={cashImage}
                        imageAlt=""
                        infoText={t('landing_page.learn_panel.cash_rewards')}
                    />
                </Col>
                <Col sm={12} md={4}>
                    <InfoGraphic
                        imageUrl={chargerImage}
                        imageAlt=""
                        infoText={t('landing_page.learn_panel.home_chargers')}
                    />
                </Col>
                <Col sm={12} md={4}>
                    <InfoGraphic
                        imageUrl={discountsImage}
                        imageAlt=""
                        infoText={t('landing_page.learn_panel.electrical_bill')}
                    />
                </Col>
            </Row>
            <h3 className="text-center mt-5 mb-3 find-out-title">{t('landing_page.learn_panel.find_out_title')}</h3>
            <p className="find-out-content">{t('landing_page.learn_panel.find_out_content')}</p>
            <p className="text-center">
                <Button className="rounded-5 button-style button-font"
                        onClick={learnPanelFindUtilityClicked}
                >{t('landing_page.learn_panel.btn_find_utility')}</Button>
            </p>
        </Container>
        </>
    );
}
