import './UtilityProgramResults.scss'
import {Container, Row, Tab, Tabs, Form, Col} from "react-bootstrap";
import {useState} from "react";
import UtilityPrograms from "./UtilityPrograms";
import {useTranslation} from 'react-i18next';

export default function UtilityProgramResults({utilityProgramData}) {

    const {t, i18n} = useTranslation();
    const [utilitySelected, setUtilitySelected] = useState(0)
    function selectUtility(event) {
        setUtilitySelected(event.target.value)
    }
        function buildSelect(utilities) {
        if (utilities == null || utilities.length === 1) {
            return;
        }
        let options = utilities.map((utility, index) => <option value={index}
                                                                key={index}>{utility.utilityName}</option>)
        return (
            <Form.Select size="lg" id="zipcode-utility-select" className="border-secondary"
                         onChange={selectUtility}>{options}</Form.Select>
        )
    }
        if (utilityProgramData != null) {
            return (
                <div className="p-0 mt-3">
                    <Col xs={12} md={6} className="mx-auto">
                        <Form.Control
                            plaintext
                            readOnly
                            className={'form-control-plaintext model-label'}
                            defaultValue={t('landing_page.utility_program_results.your_utility')}
                        />
                    </Col>
                    <Col xs={12} md={6} className="mx-auto">
                        {buildSelect(utilityProgramData)}
                    </Col>
                    <UtilityPrograms utility={utilityProgramData[utilitySelected]}/>
                </div>
            )
        }
    }