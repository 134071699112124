import './KmiSuccessMessage.scss';
import {Col, Container, Image, Row} from "react-bootstrap";
import NotificationImage from "../../assets/images/png-images/Success_notification_icon.png"
import {useTranslation} from "react-i18next";

export default function KmiSuccessMessage() {
    const { t, i18n } = useTranslation();
    return (
        <Container fluid={true} className="mt-4 pt-3 pb-3  green-box">
            <Row className={"text-start"}>
                <Col className={"ps-3"} >
                    <Image src={NotificationImage} alt={t('landing_page.kmi_success_message.success_notification_icon')} width={25}/>
                </Col>
                <Col xs={11} className={"text-start kmi_success_message-title ps-0"}>
                    {t('landing_page.kmi_success_message.title_kmi_success_message')} {}
                </Col>
            </Row>
            <Row className={"text-start pt-2"}>
                <Col className={"px-0"} >
                </Col>
                <Col xs={11} className={"text-start kmi_success_message-text ps-0"}>
                    {t('landing_page.kmi_success_message.info_kmi_success_message')}
                </Col>
            </Row>
        </Container>
    )
}