import './UtilityProgramResults.scss'
import {Col, Image, Row, Tab, Tabs} from "react-bootstrap";
import enrollmentIconImage from "../../assets/images/png-images/utilities/programs/enrollment-icon.png"
import chargingIncentivesIconImage from "../../assets/images/png-images/utilities/programs/charging-incentives-icon.png"
import {useTranslation} from "react-i18next";
import {AMPLITUDE_EVENTS, trackEvent} from "../../util/Amplitude";
import {useRecoilState} from "recoil";
import {utilitySelectedNameState, utilitySelectedProgramState} from "../../recoilState";
import {useState} from "react";


export default function UtilityPrograms({utility}) {
    const {t, i18n} = useTranslation();
    const [utilitySelectedName, setUtilitySelectedName] = useRecoilState(utilitySelectedNameState);
    const [utilitySelectedProgram, setUtilitySelectedProgram] = useRecoilState(utilitySelectedProgramState);
    const [localSelectedProgramName, setLocalSelectedProgramName] = useState('');
        const tabClicked = (key, x) => {
        const [utilityCode, programId] = key.split('-');
        const selectedProgram = utility.programs.find(program => program.id === programId);
        const eventProps = { programSelected: selectedProgram.name };
        setLocalSelectedProgramName(selectedProgram.name);

        trackEvent(AMPLITUDE_EVENTS.PROGRAM_TAB_CLICKED, eventProps);
    }

    function buildTabs(utilityCode, programs) {
        return programs.map((program, index) => {
            let tabEventKey = utilityCode + '-' + program.id
                return (
                    <Tab
                        id={"utility-programs-tab-" + index}
                        eventKey={tabEventKey} title={program.name}
                        className="program-tab"
                        key={"utility-" + utilityCode + "-programs-tab-" + index}

                    >
                        <Row className="justify-content-md-center">
                            <Col lg={9} xs={12}>
                                <h2>{program.details.title}</h2>
                                <p className="program-description">
                                    {program.details.description}
                                </p>
                                <div className="program-incentives">
                                    <Row className="mt-3">
                                        <Col xs={3} sm={2}><Image src={enrollmentIconImage}/> </Col>
                                        <Col aria-colspan="auto">
                                            <h3>{t('landing_page.utility_program_results.enrollment')}</h3>
                                            {program.details.enrollment}
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs={3} sm={2}><Image src={chargingIncentivesIconImage}/> </Col>
                                        <Col>
                                            <h3>{t('landing_page.utility_program_results.charging_incentives')}</h3>
                                            {program.details.chargingIncentives}
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Tab>
                )
            }
        )

    }
    let defaultActiveTabKey = utility.utilityCode + '-' + utility.programs[0].id;

        if (localSelectedProgramName === ''){
            setUtilitySelectedProgram(utility.programs[0].name)
        }
        else {
            setUtilitySelectedProgram(localSelectedProgramName)
        }
    const selectedUtilityName = utility.utilityName;
    setUtilitySelectedName(selectedUtilityName)

    return (
        <div id={"search-result-utility-" + utility.utilityCode}>
            <h1 className="utility-title mt-3">{utility.utilityName}</h1>
            <Tabs
                id={"utility-" + utility.utilityCode + "-programs-tabs"}
                defaultActiveKey={defaultActiveTabKey}
                className="mb-3 d-flex justify-content-center tabs program-title"
                variant="underline"
                key={"utility-" + utility.utilityCode + "-programs-tabs"}
                onSelect={tabClicked}
            >
                {buildTabs(utility.utilityCode, utility.programs)}
            </Tabs>
        </div>
    );
}
