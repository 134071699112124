import './ProcessInfoPanel.scss';
import {Row, Col, Container, Button} from "react-bootstrap";
import InfoGraphic from "../InfoGraphic/InfoGraphic";
import enrollImage from "../../assets/images/png-images/Enroll.png"
import pluginImage from "../../assets/images/png-images/Plug_in.png"
import scheduleImage from "../../assets/images/png-images/Schedule.png"
import benefitImage from "../../assets/images/png-images/Benefit.png"
import { useTranslation } from 'react-i18next';
import { trackEvent, AMPLITUDE_EVENTS } from '../../util/Amplitude';

export default function ProcessInfoPanel({ toggleDialog }) {
  const { t, i18n } = useTranslation();
    const learnPanelEnrollNowClicked = () => {
            const eventProps = { enrollButtonLocation: 'bottom' };
            trackEvent(AMPLITUDE_EVENTS.ENROLL_NOW,eventProps);
    }

    const learnPanelShowModal = () => {
        learnPanelEnrollNowClicked();
        toggleDialog();
    }

  return (
    <div className='process-info-panel'>
        <Container fluid={true} className="">
            <Row className={"g-2 bottom-gap"}>
                <Col sm={12} md={6} className="pe-0">
                    <InfoGraphic
                        imageUrl={enrollImage}
                        imageAlt=""
                        title={t('landing_page.process_info_panel.title_enroll')}
                        infoText={t('landing_page.process_info_panel.info_text_enroll')}
                        textAlign={'left'}
                        layoutMode='vertical'
                        imageClassName="image-size"
                    />
                </Col>
                <Col sm={12} md={6} className="pe-0">
                    <InfoGraphic
                        imageUrl={pluginImage}
                        imageAlt=""
                        title={t('landing_page.process_info_panel.title_plugin')}
                        infoText={t('landing_page.process_info_panel.info_text_plugin')}
                        textAlign={'left'}
                        layoutMode='vertical'
                        imageClassName="image-size"
                    />
                </Col>
            </Row>

            <Row className={"g-2 bottom-gap"}>
                <Col sm={12} md={6} className="pe-0 ">
                    <InfoGraphic
                        imageUrl={scheduleImage}
                        imageAlt=""
                        title={t('landing_page.process_info_panel.title_schedule')}
                        infoText={t('landing_page.process_info_panel.info_text_schedule')}
                        textAlign='left'
                        layoutMode='vertical'
                        imageClassName="image-size"
                    />
                </Col>
                <Col sm={12} md={6} className="pe-0">
                    <InfoGraphic
                        imageUrl={benefitImage}
                        imageAlt=""
                        title={t('landing_page.process_info_panel.title_benefit')}
                        infoText={t('landing_page.process_info_panel.info_text_benefit')}
                        textAlign={'left'}
                        layoutMode='vertical'
                        imageClassName="image-size"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button
                        className={"rounded-5 button-style button-font"}
                        onClick={learnPanelShowModal}
                    >{t('landing_page.process_info_panel.btn_enroll')}</Button>
                </Col>
            </Row>
        </Container>
    </div>
  );
}
