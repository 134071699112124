import './WhatYouNeedPanel.scss';
import {Col, Container, Row} from "react-bootstrap";
import InfoGraphic from '../InfoGraphic/InfoGraphic';
import electricHybridImage from "../../assets/images/png-images/Electric_hybrid_vehicle.png"
import fordPassAppImage from "../../assets/images/png-images/Fordpass_app.png"
import homeChargeImage from "../../assets/images/png-images/Home_charge.png"
import participatingUtilityImage from "../../assets/images/png-images/Participating_utility.png"
import { useTranslation } from 'react-i18next';
export default function WhatYouNeedPanel() {
  const { t, i18n } = useTranslation();

  return (
      <Container fluid={true} className="pt-5 pb-5 what-you-need-panel">
          <Container fluid={true} className={"pt-5 pb-5 what-you-need-info"}>
            <h2 className="text-center h2-font-colour what-you-need-title vertical-gap mb-3">{t('landing_page.what_you_need_panel.title')}</h2>
              <Row className="align-content-md-center">
                  <Col sm={12} md={6} className={'gap-below gap-above'}>
                    <InfoGraphic
                        imageUrl={electricHybridImage}
                        imageAlt=""
                        infoText={t('landing_page.what_you_need_panel.info_text_electric_hybrid')}
                        textColour={'info-text-colour-gray94'}
                        layoutMode='horizontal'
                        textAlign={"left"}
                        incomingClass={'WhatYouNeedPanel'}
                        imageClassName={"image-size"}
                    />
                  </Col>
                  <Col sm={12} md={6} className={'gap-below'}>
                    <InfoGraphic
                      imageUrl={fordPassAppImage}
                      imageAlt=""
                      infoText={t('landing_page.what_you_need_panel.info_fordpass_app')}
                      textColour={'info-text-colour-gray94'}
                      layoutMode='horizontal'
                      textAlign={"left"}
                      incomingClass={'WhatYouNeedPanel'}
                      imageClassName={"image-size"}
                    />
                  </Col>
              </Row>
              <Row className={"vertical-gap gap-below"}>
                  <Col sm={12} md={6}>
                    <InfoGraphic
                        imageUrl={homeChargeImage}
                        imageAlt=""
                        infoText={t('landing_page.what_you_need_panel.info_home_charge')}
                        textColour={'info-text-colour-gray94'}
                        layoutMode='horizontal'
                        incomingClass={'WhatYouNeedPanel'}
                        textAlign={"left"}
                        imageClassName={"image-size"}
                    />
                  </Col>
                  <Col sm={12} md={6} className={'gap-above'}>
                    <InfoGraphic
                        imageUrl={participatingUtilityImage}
                        imageAlt=""
                        infoText={t('landing_page.what_you_need_panel.info_text_participating_utility')}
                        textColour={'info-text-colour-gray94'}
                        layoutMode='horizontal'
                        textAlign={"left"}
                        incomingClass={'WhatYouNeedPanel'}
                        imageClassName={"image-size"}
                    />
                  </Col>
              </Row>
          </Container>
      </Container>
  );
}
