const origin = window.location.origin;

const prod = {
    production: true,

    enrollmentBrokerApi: {
        BASE_URL: 'https://api.pd01e.gcp.ford.com/smartgrid-enrollment-broker',
        KMI_PATH: '/public/api/v1/marketing/keepMeInformed',
        SEARCH_PROGRAMS_PATH: '/utility/programs/byPostcode/',
    },

    AMPLITUDE_API_KEY: process.env.REACT_APP_AMPLITUDE_API_KEY
};

const dev = {
    production: false,

    enrollmentBrokerApi: {
        BASE_URL: 'https://api.qa01i.gcp.ford.com/smartgrid-enrollment-broker',
        // BASE_URL: 'https://grid-programs-int.apps.pp01i.edc1.cf.ford.com',
        KMI_PATH: '/public/api/v1/marketing/keepMeInformed',
        SEARCH_PROGRAMS_PATH: '/utility/programs/byPostcode/',
    },

    AMPLITUDE_API_KEY: process.env.REACT_APP_AMPLITUDE_API_KEY
};
const config = process.env.NODE_ENV === 'development' ? dev : prod;

export default config;
